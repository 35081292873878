import ConfirmationAndPasswordModal from '@components/auto-account-creation/ConfirmationAndPasswordModal/ConfirmationAndPasswordModal';
import PasswordModal, {
	parseEmail,
} from '@components/auto-account-creation/PasswordModal/PasswordModal';
import VRMConfirmationModal from '@components/auto-account-creation/VRMConfirmationModal/VRMConfirmationModal';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import * as ExperimentActions from '@redux/experiments/actions/thunks';
import * as RfqActions from '@redux/rfq/actionCreators';
import { changePassword as changePasswordAction } from '@redux/rfq/thunks';
import { fetchTemporaryPassword } from '@redux/rfq/utils';
import type { FESharedProfile } from '@typings/api';
import { determineReferredMarketCode } from '@utils/index';
import { isFromSearchPage } from '@utils/regex';
import Spinner from '@xo-union/tk-component-spinner';
import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import React, { lazy, Suspense, useEffect, useState, type FC } from 'react';
import { connect } from 'react-redux';
import AnalyticsEvents from '../../../../../../../../../constants/analytics';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../../../../../../../constants/cookies';
import {
	getVisitId,
	getVisitorId,
} from '../../../../../../../../../utils/cookies';
import { cookieGetItem } from '../../../../../../../../utils/cookie/consentManagementCookie';

const { SAVE_PASSWORD, PASSWORD_CREATE } = AnalyticsEvents;
const VRM = lazy(
	() => import(/* webpackChunkName: "vrm" */ '../VRM/VrmContainer'),
);

interface AnalyticsArgs {
	action: string;
	sourceContent: string;
}

interface StateProps {
	anonymousId: string;
	buttonType: string;
	changePasswordStatus: Rfq.ChangePasswordStatus;
	email: string;
	isReferred: boolean;
	isUserRegistered: boolean;
	marketCode: string;
	member: Membership.Member | null;
	pageType: string;
	referrer: string;
	storefront: Vendor.Decorated;
	userId?: string;
}

interface DispatchProps {
	changePassword: (newPassword: string, type?: string) => void;
	setJourneyStatus: (
		userJourneyStatus: 'init' | 'exit-early' | 'complete',
	) => void;
}

interface OwnProps {
	initiator: string | null;
	modal: string | null;
	recommendedVendors: API.FESharedProfile[];
	rfqType?: string;
	setModal: (modal: string | null) => void;
	vendorRaw: Vendor.Raw;
	reportServerSideExperiment: (experiment: string) => void;
}

export enum Steps {
	Vrm = 'vrm',
	VrmConfirm = 'vrm-confirm',
	PasswordModal = 'password-modal',
	ConfirmationAndPassword = 'confirmation-password-modal',
}

export type VrmModalsProps = OwnProps & StateProps & DispatchProps;

const VrmModals: FC<VrmModalsProps> = (props) => {
	const {
		anonymousId,
		buttonType,
		changePassword,
		changePasswordStatus,
		email,
		initiator,
		isReferred,
		isUserRegistered,
		marketCode,
		member,
		modal,
		pageType,
		recommendedVendors,
		referrer,
		setJourneyStatus,
		setModal,
		storefront,
		userId,
		vendorRaw,
	} = props;
	const { track } = useAnalyticsContext();
	const [contactedVendors, setContactedVendors] = useState(
		[] as API.SendBulkInquirySuccess[],
	);
	const [contactedVendorsCount, setContactedVendorsCount] = useState(1); // 1 is for the direct lead
	const [password, setPassword] = useState('');
	const media = useDesktopMedia();
	const visitId = getVisitId();
	const visitorId = getVisitorId();

	const shouldVrmAppear = recommendedVendors?.length > 0;

	useEffect(() => {
		if (modal === 'vrm' && !shouldVrmAppear) {
			setModal(
				isUserRegistered ? Steps.VrmConfirm : Steps.ConfirmationAndPassword,
			);
		}
	}, [modal, shouldVrmAppear, setModal, isUserRegistered]);

	const populateContactedVendorsProperties = (
		contactedVendor: API.SendBulkInquirySuccess,
	) => ({
		...contactedVendor,
		...recommendedVendors.find((r) => r.id === contactedVendor.storefrontId),
	});

	const onVRMSubmit = (contactedVendors: API.SendBulkInquirySuccess[]) => {
		const contactedVendorsCount = contactedVendors.length + 1; // 1 is for the direct lead
		const populatedContactedVendors = contactedVendors.map(
			populateContactedVendorsProperties,
		);

		setContactedVendors(populatedContactedVendors);
		setContactedVendorsCount(contactedVendorsCount);
		setModal(
			fetchTemporaryPassword()
				? Steps.ConfirmationAndPassword
				: Steps.VrmConfirm,
		);
	};

	const onVrmClose = () => {
		setJourneyStatus('exit-early');
		// TODO find a more elegant solution: this is to prevent the password modal automatically closing because of a hash change event
		setTimeout(() => {
			setModal(fetchTemporaryPassword() ? Steps.PasswordModal : null);
		}, 0);
	};

	const handleEarlyExit = (analytics?: AnalyticsArgs) => {
		if (analytics) {
			const { sourceContent, action } = analytics;
			handleAnalytics(sourceContent, action);
		}
		setModal(null);
		setJourneyStatus('exit-early');
	};

	const handlePasswordSubmit = (analytics: AnalyticsArgs) => {
		const { sourceContent, action } = analytics;
		changePassword(email, password);
		handleAnalytics(sourceContent, action);
		setModal(null);
	};

	const handleAnalytics = (sourceContent: string, action: string) => {
		const properties = {
			action,
			anonymousId,
			platform: media.yes ? 'desktop web' : 'mobile web',
			sourceContent,
			visitId,
			visitorId,
			userId,
		};

		track({
			event: AnalyticsEvents.POST_LEAD_INTERACTION,
			properties,
		});
	};

	const handleVrmConfirmationNextButtonClick = (analytics?: AnalyticsArgs) => {
		if (analytics) {
			const { sourceContent, action } = analytics;
			handleAnalytics(sourceContent, action);
		}
		setModal(null);
	};

	const isFromSearch = isFromSearchPage(referrer);
	const cookieMarketCode = cookieGetItem(VENDOR_REFERRED_MARKET_CODE);
	const referred = determineReferredMarketCode({
		cookieMarketCode,
		isFromSearch,
		isReferred,
		marketCode,
	});

	return (
		<>
			<Suspense fallback={<Spinner />}>
				<VRM
					buttonType={buttonType}
					initiator={initiator}
					isOpen={modal === 'vrm' && recommendedVendors.length > 0}
					member={member || null}
					onClose={onVrmClose}
					onSubmit={onVRMSubmit}
					pageType={pageType}
					recommendedVendors={recommendedVendors}
					referred={referred}
					vendorRaw={vendorRaw}
					storefront={storefront}
				/>
				{/* for existing users, even without indirect leads */}
				{modal === Steps.VrmConfirm && (
					<VRMConfirmationModal
						isModalOpen={modal === Steps.VrmConfirm}
						closeCallback={handleEarlyExit}
						onNextButtonClick={handleVrmConfirmationNextButtonClick}
						contactedVendors={
							(contactedVendors.length
								? contactedVendors
								: [vendorRaw]) as FESharedProfile[]
						}
						indirectLeadsCount={contactedVendorsCount - 1}
						sourcePage={pageType}
					/>
				)}
				{/* for new users who sent indirect leads or no VRM appeared */}
				{modal === Steps.ConfirmationAndPassword && (
					<ConfirmationAndPasswordModal
						sourcePage={pageType}
						isModalOpen={modal === Steps.ConfirmationAndPassword}
						closeCallback={handleEarlyExit}
						changePasswordStatus={changePasswordStatus}
						contactedVendors={
							(contactedVendors.length
								? contactedVendors
								: [vendorRaw]) as FESharedProfile[]
						}
						indirectLeadsCount={contactedVendorsCount - 1}
						handleSubmit={handlePasswordSubmit}
						password={password}
						updatePassword={setPassword}
					/>
				)}
				{/* for new users who did not send indirect leads (closed VRM) */}
				{modal === Steps.PasswordModal && (
					<PasswordModal
						isModalOpen={modal === Steps.PasswordModal}
						closeCallback={handleEarlyExit}
						changePasswordStatus={changePasswordStatus}
						handleSubmit={handlePasswordSubmit}
						submitAnalytics={{
							action: SAVE_PASSWORD,
							sourceContent: PASSWORD_CREATE,
						}}
						password={password}
						updatePassword={setPassword}
					/>
				)}
			</Suspense>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => {
	return {
		anonymousId: state.settings.anonymousId,
		buttonType: state.rfq.buttonType,
		changePasswordStatus: state.rfq.changePasswordStatus,
		email: parseEmail(state),
		isReferred: state.settings.isReferred,
		marketCode: state.settings.marketCode,
		member: state.membership.member,
		pageType: state.page.pageType,
		referrer: state.requestInfo.info.referrer,
		storefront: state.vendor.vendor,
		userId: state.membership?.member?.id,
		isUserRegistered: state.rfq.isUserRegistered,
	};
};

const mapDispatchToProps = {
	changePassword: changePasswordAction,
	setJourneyStatus: RfqActions.setUserJourneyStatus,
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

export default connect(mapStateToProps, mapDispatchToProps)(VrmModals);
