export const fetchConversations = (
	payload: MessagedVendors.Conversations,
): MessagedVendors.FetchConversations => {
	return {
		type: 'CONVERSATIONS_LOADED',
		payload,
	};
};

export const addConversation = (
	newConversation: MessagedVendors.Conversations,
): MessagedVendors.AddConversation => {
	return {
		type: 'CONVERSATION_ADDED',
		payload: newConversation,
	};
};

export const setConversationsTimestamps = (
	payload: MessagedVendors.Timestamps[],
): MessagedVendors.SetConversationsTimestamps => {
	return {
		type: 'SET_CONVERSATION_TIMESTAMPS',
		payload,
	};
};
