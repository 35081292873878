import { selectAssignment } from '@redux/experiments/selectors/index';
import type { VRMLegalUpdateAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const VRMLegalUpdateSelector = (state: State) => {
	if (state.viewport.isMobile || state.viewport.isTablet) {
		return null;
	}

	return selectAssignment(state, [
		'VRMLegalUpdate',
	]) as VRMLegalUpdateAssignment;
};

export { VRMLegalUpdateSelector };
