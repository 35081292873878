import { selectAssignment } from '@redux/experiments/selectors/index';
import type { VRMLegalUpdateMobileAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const VRMLegalUpdateMobileSelector = (state: State) => {
	if (!state.viewport.isMobile) {
		return null;
	}

	return selectAssignment(state, [
		'VRMLegalUpdateMobile',
	]) as VRMLegalUpdateMobileAssignment;
};

export { VRMLegalUpdateMobileSelector };
