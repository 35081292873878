import { selectAssignment } from '@redux/experiments/selectors/index';
import type { VendorWidgetOnRfqModalsAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const vendorWidgetOnRfqModalsSelector = (state: State) =>
	selectAssignment(state, [
		'vendorWidgetOnRfqModals',
	]) as VendorWidgetOnRfqModalsAssignment;

export const isVendorWidgetOnRfqModalsSelector = (state: State) => {
	const assignment = vendorWidgetOnRfqModalsSelector(state);
	return assignment?.startsWith('variant1');
};
