import { selectAssignment } from '@redux/experiments/selectors/index';
import type { VendorWidgetPlacementAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const vendorWidgetPlacementSelector = (state: State) => {
	// Only on Desktop
	if (state.viewport.isMobile) return null;

	return selectAssignment(state, [
		'vendorWidgetPlacement',
	]) as VendorWidgetPlacementAssignment;
};
