import { selectAssignment } from '@redux/experiments/selectors';
import { experiments } from '@settings';
import type { Name, Settings } from 'types/experiments';
import { reportAssignment } from './utils';

const getExperimentFromId = (experimentId: Experiments.Id) => {
	const experiment = Object.entries(experiments).find(
		([key, value]) => value === experimentId,
	) as unknown as Settings[Name];
	return experiment[0] as Name;
};

export const reportServerSideExperiment =
	(experimentId: Experiments.Id): Redux.ThunkResult<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState();
		const {
			experiments: { configurations },
			settings: { anonymousId },
		} = state;
		const assignment = selectAssignment(state, [
			getExperimentFromId(experimentId),
		]);
		if (!assignment) {
			return;
		}

		const experimentConfiguration = configurations[experimentId];
		const variants: API.FlipperVariant[] =
			experimentConfiguration?.variants || [];
		const variant = variants?.find((v) => v.variant_name === assignment);

		if (variant) {
			reportAssignment(anonymousId, experimentId, variant.variant_id);
		}
	};
