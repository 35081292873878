import { selectAssignment } from '@redux/experiments/selectors/index';
import type { EmailDomainSuggesterAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const emailDomainSuggesterSelector = (state: State) =>
	selectAssignment(state, [
		'emailDomainSuggester',
	]) as EmailDomainSuggesterAssignment;

export const isEmailDomainSuggesterSelector = (state: State) => {
	const assignment = emailDomainSuggesterSelector(state);
	return assignment?.startsWith('variant1');
};
